export const COVEN_ADDRESS = "0x5180db8f5c931aae63c74266b211f580155ecac8";
export const BACKGROUND_COLORS = [
  "aquamarine",
  "beige",
  "blanchedalmond",
  "darkseagreen",
  "honeydew",
  "khaki",
  "lemonchiffon",
  "linen",
  "mintcream",
  "papayawhip",
  "pink",
  "thistle",
];
